import './shipment-notes.component.scss';

export interface ShipmentNotesProps {
  notes: shipmentNoteJSON[];
  isDetail?: boolean;
}

export const ShipmentNotes = ({ notes = [], isDetail }: ShipmentNotesProps) => {
  const notesToDisplay = isDetail ? notes : notes.slice(0, 2);

  return (
    <div className="shipment-note-content">
      {notesToDisplay.map((d, i) => (
        <p className="shipment-note" key={i}>
          {d.note}
        </p>
      ))}
    </div>
  );
};
