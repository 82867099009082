import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AvailableLoadOfferRejectionReason from 'shared/enums/available-load-offer-rejection-reason';
import AvailableLoadOfferStatus from 'shared/enums/available-load-offer-status';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { LoadSummaryCarrierOffer, LoadSummaryOffer, } from 'shared/models/loads/load-summaries/load-summary-carrier-offer';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OFFER_STATUS } from '../constants';
import { updateOfferStatus } from 'shared/find-loads/redux/find-loads.actions';
import { useIsBookUnavailable } from './use-is-book-unavailable.hook';
import { OfferType } from 'shared/enums/offer-type.enum';
import { calculateOfferTimerExpirationDate } from 'app/util/offers/offer-expiration-calculator';
import { Offer } from 'shared/models/offers/offer.model';
import { OfferStatus as OfferStatuses } from 'shared/enums/offer-status.enum';
import { updateOfferStatusOnOffersStore } from 'shared/offers/redux/offers.actions';
import { loadSummaryCarrierOfferMapper } from 'shared/offers/load-summary-carrier-offer-mapper';
import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';
import { CarrierQualificationStatus } from 'shared/enums/carrier-qualification-status.enum';
import { IsCarrierRestrictedOnLoad } from 'app/util/loads/cap-restricted-function';

export interface OfferStatus {
  isOfferOpen: boolean;
  isOfferClosed: boolean;
  isOfferAccepted: boolean;
  isOfferAcceptedByDataScience: boolean;
  isOfferRejected: boolean;
  isOfferRejectedForPrice: boolean;
  isOfferRejectedForCarrierValidation: boolean;
  isOfferIgnored: boolean;
  isOfferCountered: boolean;
  isOfferExpired: boolean;
  isUnableToProcess: boolean;
  isOfferNotConsidered: boolean;
  isOfferSubmissionError: boolean;
  availableLoadOfferStatus: AvailableLoadOfferStatus;
  offerExpirationDate: Date;
  isFinalNegotiation?: boolean;
}

export interface AvailableLoadOfferAction {
  resourceKey: ResourceKey;
  isCapLocked?: boolean;
  isCapRestricted?: boolean;
  canBook?: boolean;
  canOffer?: boolean;
  canMakeNewOffer?: boolean;
  canMakeCounterOffer?: boolean;
}

export interface BookPrice {
  value: number;
  currencyCode: string;
}

export interface OfferState {
  carrierOffer?: LoadSummaryCarrierOffer;
  offerStatus?: OfferStatus;
  availableLoadOfferAction?: AvailableLoadOfferAction;
  bookPrice?: BookPrice;
}

const buildBookPrice = (
  load: AvailableLoadSummary,
  isLoadBookUnavailable: boolean
): BookPrice | null => {
  return !isLoadBookUnavailable &&
    load?.binRateCost?.totalCost &&
    load?.binRateCost?.currencyCode
    ? { value: load.totalCost, currencyCode: load?.binRateCost?.currencyCode }
    : null;
};

export const useCarrierOffer = (
  load: AvailableLoadSummary,
  currentOffer?: LoadSummaryCarrierOffer,
  isFromOffersPage?: boolean,
) => {
  const dispatch = useDispatch();
  const isLoadBookUnavailable = useIsBookUnavailable(load);

  const findLoadsCarrierOffer: LoadSummaryCarrierOffer = useSelector(
    (state: NavCarrierState) => {
      let loadSummaryCarrierOffer: LoadSummaryCarrierOffer;
      const loadCarrierOffers: LoadSummaryCarrierOffer[] = state?.findLoads?.results?.offers;
      const hasOffers: boolean = loadCarrierOffers?.length > 0;
      if (hasOffers) {
        loadSummaryCarrierOffer = loadCarrierOffers.find(
          (x) => x.loadNumber === load?.number
        );
      }
      return loadSummaryCarrierOffer ?? null;
    }
  );

  const getOfferFromState: LoadSummaryCarrierOffer = useSelector(
    (state: NavCarrierState) => {
      if (!load) { return null; }
      let selectedOffer: Offer;
      const loadCarrierOffers: Offer[] = state?.offers;
      const hasOffers: boolean = loadCarrierOffers?.length > 0;
      if (hasOffers && currentOffer?.latestOffer?.offerId > 0) {
        selectedOffer = loadCarrierOffers.find(c => c.loadNumber === load.number && c.offerId === currentOffer?.latestOffer?.offerId);
      }
      return selectedOffer ? loadSummaryCarrierOfferMapper(selectedOffer) : null;
    }
  );

  const offersPageFindOffer: LoadSummaryCarrierOffer = useSelector(
    (state: NavCarrierState) => {
      if (currentOffer) return getOfferFromState;
      if (!load) return null;

      const loadCarrierOffers: Offer[] = state?.offers;
      const hasOffers: boolean = loadCarrierOffers?.length > 0;
      if (!hasOffers) return null;

      const latestOffer = findNewOffer(loadCarrierOffers, load.number) ?? findLatestOffer(loadCarrierOffers, load.number);

      return latestOffer ? loadSummaryCarrierOfferMapper(latestOffer) : null;

      function findNewOffer(offers: Offer[], loadNumber: number): Offer | null {
        return offers.find(o => o.loadNumber === loadNumber && !o.offerId);
      }

      function findLatestOffer(offers: Offer[], loadNumber: number): Offer | null {
        const offersOnLoad = offers.filter(c => c.loadNumber === loadNumber);
        if (offersOnLoad.length === 0) return null;

        /*
          Long-term we should use enteredDate here instead of offerId, as offerId will eventually be replaced by a GUID.
          Though, with the current state of how enteredDate is determined on the frontend, the initial offer that gets rejected has the same enteredDate as the new offer
          because the Date object for enteredDate appears to be dropping the milliseconds.
          Which causes this function to be based on the ordering of the passed in offers, which results in selecting the original offer (instead of the new offer).
        */
        return offersOnLoad.reduce((latestOffer, currentOffer) => latestOffer.offerId > currentOffer.offerId ? latestOffer : currentOffer);
      }
    }
  );

  // tslint:disable-next-line:no-shadowed-variable
  const getLocalDateTime = (offer: LoadSummaryOffer) => {
    let createdDateTimeString: string =
      offer.createdDateTime;

    if (createdDateTimeString && createdDateTimeString.slice(-1) !== 'Z') {
      // Ensure date string is treated as UTC.
      createdDateTimeString = `${createdDateTimeString}Z`;
    }

    return createdDateTimeString ? new Date(createdDateTimeString) : null;
  };

  const isFromOffers = isFromOffersPage ?? false;
  const carrierOffer: LoadSummaryCarrierOffer = isFromOffers ? offersPageFindOffer : (currentOffer ?? findLoadsCarrierOffer);

  const { loadDetailMakeOffer,
    showMakeOffer,
    showMakeOfferHazmat,
    showMakeOfferStf,
    showMakeOfferFlatbed,
    showMakeOfferTanker,
    showMakeOfferDroptrailer,
    showMakeOfferTeamload,
    showMakeOfferActivitydate,
    showMakeCounterOfferWeb,
    findLoadsMaintenanceBannerWeb,
    findLoadsCapRestrictionsWeb
  } = useFlags();

  const carrierDetails = useCarrierDetails();

  const [offerState, setOfferState] = useState<OfferState>({
    carrierOffer: carrierOffer,
    bookPrice: buildBookPrice(load, isLoadBookUnavailable),
  });

  useEffect(() => {
    if (!load?.number) {
      setOfferState(null);
    } else {
      setOfferState({
        carrierOffer: carrierOffer,
        bookPrice: buildBookPrice(load, isLoadBookUnavailable),
      });
    }
  }, [load?.number]);

  useEffect(() => {
    let state: OfferState = {
      ...offerState,
    };

    if (!state.carrierOffer && carrierOffer || state.carrierOffer !== carrierOffer) {
      state.carrierOffer = carrierOffer;
    }

    let hasOfferStatusChanged = false;
    let hasAvailableLoadOfferActionChanged = false;
    const hasBinCost: boolean = load?.hasRateObject && !!load?.totalCost;

    if (carrierOffer?.latestOffer) {
      const offer: LoadSummaryOffer = carrierOffer?.latestOffer;
      const offerExpireDateTime: Date = calculateOfferTimerExpirationDate(getLocalDateTime(offer));

      let availableLoadOfferStatus: AvailableLoadOfferStatus;
      let status = (offer.offerStatus ?? '').toUpperCase();
      switch (status) {
        case AvailableLoadOfferStatus.COUNTERED:
        case AvailableLoadOfferStatus.OPEN:
        case OFFER_STATUS.UNABLE_TO_PROCESS:
        case OFFER_STATUS.NOT_CONSIDERED:
          availableLoadOfferStatus = AvailableLoadOfferStatus.OPEN;
          break;
        case AvailableLoadOfferStatus.ACCEPTED:
        case AvailableLoadOfferStatus.REJECTED:
        case AvailableLoadOfferStatus.CLOSED:
        case AvailableLoadOfferStatus.EXPIRED:
        case AvailableLoadOfferStatus.PENDING:
          availableLoadOfferStatus = status;
      }

      const rejectionReason = (offer?.rejectionReason ?? '').toUpperCase();
      const isOfferOpen: boolean = availableLoadOfferStatus === AvailableLoadOfferStatus.OPEN && offer.offerType === OfferType.Truck;
      const isOfferClosed: boolean = availableLoadOfferStatus === AvailableLoadOfferStatus.CLOSED;
      const isOfferAccepted: boolean = availableLoadOfferStatus === AvailableLoadOfferStatus.ACCEPTED && offer.offerType === OfferType.Truck;
      const isOfferAcceptedByDataScience: boolean = isOfferAccepted && offer.acceptedByDataScience;
      const isOfferCountered: boolean = [AvailableLoadOfferStatus.OPEN, AvailableLoadOfferStatus.ACCEPTED, AvailableLoadOfferStatus.COUNTERED, AvailableLoadOfferStatus.PENDING].includes(status as AvailableLoadOfferStatus)
        && offer.offerType === OfferType.Load;
      const isOfferRejected: boolean = availableLoadOfferStatus === AvailableLoadOfferStatus.REJECTED;
      const isOfferRejectedForPrice: boolean = isOfferRejected && rejectionReason === AvailableLoadOfferRejectionReason.PRICE;
      const isOfferRejectedForCarrierValidation: boolean = isOfferRejected && rejectionReason === AvailableLoadOfferRejectionReason.CARRIER_VALIDATION;
      const isOfferIgnored: boolean = isOfferRejected && rejectionReason === AvailableLoadOfferRejectionReason.IGNORED;
      const isOfferExpired: boolean = availableLoadOfferStatus === AvailableLoadOfferStatus.EXPIRED
        || ((isOfferCountered || isOfferAcceptedByDataScience) && offerExpireDateTime?.getTime() < Date.now())
        || isOfferIgnored;
      const isFinalNegotiation = offer?.isFinalNegotiation;

      const useBookPrice: boolean = (isOfferAccepted || isOfferCountered)
        && !isOfferExpired
        && !isOfferRejected
        && !isOfferOpen;

      let bookPriceValue: number;
      let bookPriceCurrencyCode: string;

      if (useBookPrice) {
        bookPriceValue = offer.price;
        bookPriceCurrencyCode = offer.currencyCode;
      } else if (hasBinCost) {
        bookPriceValue = load?.totalCost;
        bookPriceCurrencyCode = load?.currencyCode;
      }
      const isOfferSubmissionError = offer.offerStatus === OFFER_STATUS.ERROR || offer.offerStatus === OFFER_STATUS.MAXIMUM_NUMBER_OF_OFFERS_SUBMITTED;
      const isOfferUnableToProcess = offer.offerStatus === OFFER_STATUS.UNABLE_TO_PROCESS;
      const isOfferNotConsidered = offer.offerStatus === OFFER_STATUS.NOT_CONSIDERED;

      hasOfferStatusChanged =
        state?.offerStatus?.isOfferOpen !== isOfferOpen
        || state?.offerStatus?.isOfferClosed !== isOfferClosed
        || state?.offerStatus?.isOfferAccepted !== isOfferAccepted
        || state?.offerStatus?.isOfferAcceptedByDataScience !== isOfferAcceptedByDataScience
        || state?.offerStatus?.isOfferIgnored !== isOfferIgnored
        || state?.offerStatus?.isOfferRejected !== isOfferRejected
        || state?.offerStatus?.isOfferRejectedForPrice !== isOfferRejectedForPrice
        || state?.offerStatus?.isOfferRejectedForCarrierValidation !== isOfferRejectedForCarrierValidation
        || state?.offerStatus?.availableLoadOfferStatus !== availableLoadOfferStatus
        || state?.bookPrice?.currencyCode !== bookPriceCurrencyCode
        || state?.bookPrice?.value !== bookPriceValue
        || state?.offerStatus?.offerExpirationDate?.getTime() !== offerExpireDateTime?.getTime()
        || state?.offerStatus.isOfferSubmissionError !== isOfferSubmissionError
        || state?.offerStatus.isUnableToProcess !== isOfferUnableToProcess
        || state?.offerStatus?.isOfferNotConsidered !== isOfferNotConsidered
        || state?.offerStatus.isOfferCountered !== isOfferCountered
        || state?.offerStatus?.isFinalNegotiation !== isFinalNegotiation;

      if (hasOfferStatusChanged) {
        state = {
          ...state,
          offerStatus: {
            isOfferOpen: isOfferOpen,
            isOfferCountered: isOfferCountered,
            isOfferClosed: isOfferClosed,
            isOfferAccepted: isOfferAccepted,
            isOfferAcceptedByDataScience: isOfferAcceptedByDataScience,
            isOfferRejected: isOfferRejected,
            isOfferRejectedForPrice: isOfferRejectedForPrice,
            isOfferRejectedForCarrierValidation: isOfferRejectedForCarrierValidation,
            isOfferIgnored: isOfferIgnored,
            isOfferExpired: isOfferExpired,
            isOfferSubmissionError: isOfferSubmissionError,
            isUnableToProcess: isOfferUnableToProcess,
            isOfferNotConsidered: isOfferNotConsidered,
            availableLoadOfferStatus: availableLoadOfferStatus,
            offerExpirationDate: offerExpireDateTime,
            isFinalNegotiation: isFinalNegotiation
          },
          bookPrice: isLoadBookUnavailable ? null : { value: bookPriceValue, currencyCode: bookPriceCurrencyCode },
        };
      }
    }

    if (load) {
      const hasOffer: boolean = !!state?.offerStatus;
      const isOfferOpen: boolean = hasOffer && state.offerStatus.isOfferOpen;
      const isOfferAccepted: boolean = hasOffer && state.offerStatus.isOfferAccepted;
      const isOfferCountered: boolean = hasOffer && state.offerStatus.isOfferCountered;
      const isOfferExpired: boolean = hasOffer && state.offerStatus.isOfferExpired;
      const isOfferRejected: boolean = hasOffer && state.offerStatus.isOfferRejected;
      const isOfferRejectedForPrice: boolean = hasOffer && state.offerStatus.isOfferRejectedForPrice;
      const isOfferRejectedForCarrierValidation: boolean = hasOffer && state.offerStatus.isOfferRejectedForCarrierValidation;
      const isOfferIgnored: boolean = hasOffer && state.offerStatus.isOfferIgnored;
      const isFinalNegotiation = state?.offerStatus?.isFinalNegotiation;
      const isWebExclusive: boolean = load?.isWebExclusive;
      const isCarrierCapRestricted: boolean = carrierDetails?.carrierQualificationStatus == CarrierQualificationStatus.RESTRICTED;
      const isOfferError: boolean = hasOffer && state.offerStatus.isOfferSubmissionError;

      const isCarrierCapLocked: boolean = findLoadsCapRestrictionsWeb
        && (isCarrierCapRestricted || IsCarrierRestrictedOnLoad((load.carrierTier ?? 'None'), carrierDetails?.capCode));

      const canBook: boolean = !isLoadBookUnavailable && !isCarrierCapLocked
        && (((isOfferCountered || isOfferAccepted) && !isOfferExpired)
          || ((!hasOffer || isOfferExpired || isOfferOpen || (isOfferRejected && !isOfferIgnored) || isOfferError) && hasBinCost))
        && !isOfferRejectedForCarrierValidation;

      const isCarrierEligibleToMakeOffer: boolean = !isLoadBookUnavailable
        && !isCarrierCapLocked
        && !load.isNotOfferable
        && loadDetailMakeOffer
        && (hasBinCost || isLoadOfferable(load));

      const canOffer: boolean = isCarrierEligibleToMakeOffer && !hasOffer;

      const canMakeNewOffer: boolean = isCarrierEligibleToMakeOffer
        && !isOfferRejectedForCarrierValidation && (isOfferRejectedForPrice || (isOfferRejected && isFinalNegotiation === false));

      const canMakeCounterOffer = showMakeCounterOfferWeb
        && isCarrierEligibleToMakeOffer
        && !isOfferIgnored
        && (isOfferCountered && !isOfferExpired && !isFinalNegotiation);

      let resourceKey: ResourceKey;

      if(findLoadsMaintenanceBannerWeb) {
        resourceKey = 'CONTACT_CHR';
      }
      else if (canBook && (canOffer || canMakeNewOffer)) {
        resourceKey = 'BOOK_OR_OFFER_ACTION';
      } else if (canBook && canMakeCounterOffer) {
        resourceKey = 'BOOK_OR_COUNTER_ACTION';
      } else if (canBook) {
        resourceKey = 'BOOK';
      } else if (canOffer) {
        resourceKey = 'MAKE_OFFER';
      } else if (canMakeCounterOffer) {
        resourceKey = 'MAKE_COUNTER_OFFER';
      } else if (canMakeNewOffer) {
        resourceKey = 'MAKE_NEW_OFFER';
      } else if (!isWebExclusive) {
        resourceKey = 'CONTACT_CHR';
      }

      hasAvailableLoadOfferActionChanged = state?.availableLoadOfferAction?.resourceKey !== resourceKey
        || state?.availableLoadOfferAction?.isCapLocked !== isCarrierCapLocked
        || state?.availableLoadOfferAction?.isCapRestricted !== isCarrierCapRestricted
        || state?.availableLoadOfferAction?.canBook !== canBook
        || state?.availableLoadOfferAction?.canOffer !== canOffer
        || state?.availableLoadOfferAction?.canMakeNewOffer !== canMakeNewOffer
        || state?.availableLoadOfferAction?.canMakeCounterOffer !== canMakeCounterOffer;

      if (hasAvailableLoadOfferActionChanged) {
        state = {
          ...state,
          availableLoadOfferAction: {
            resourceKey: resourceKey,
            isCapLocked: isCarrierCapLocked,
            isCapRestricted: isCarrierCapRestricted,
            canBook: canBook,
            canOffer: canOffer,
            canMakeNewOffer: canMakeNewOffer,
            canMakeCounterOffer: canMakeCounterOffer,
          },
          bookPrice: buildBookPrice(load, isLoadBookUnavailable),
        };
      }
    }
    if (hasOfferStatusChanged || hasAvailableLoadOfferActionChanged) {
      setOfferState(state);
    }
  }, [
    offerState?.offerStatus,
    carrierOffer,
    carrierOffer?.latestOffer?.offerStatus,
    load,
    offerState?.offerStatus?.isOfferExpired,
    isLoadBookUnavailable,
    carrierDetails?.carrierQualificationStatus,
    carrierDetails?.capCode
  ]);

  useEffect(() => {
    if (offerState?.offerStatus?.isOfferAcceptedByDataScience || offerState?.offerStatus?.isOfferCountered) {
      const msUntilOfferExpire = offerState?.offerStatus?.offerExpirationDate?.getTime() - Date.now();
      if (msUntilOfferExpire > 0) {
        window.setTimeout(() => {
          dispatch(
            updateOfferStatus({
              loadNumber: offerState.carrierOffer.loadNumber,
              updatedStatus: 'EXPIRED',
            })
          );
          dispatch(
            updateOfferStatusOnOffersStore({
              loadNumber: offerState.carrierOffer.loadNumber,
              offerId: offerState.carrierOffer.latestOffer?.offerId,
              updatedStatus: OfferStatuses.EXPIRED,
            })
          );
        }, msUntilOfferExpire);
      }
    }
  }, [
    load?.number,
    offerState?.offerStatus?.isOfferAcceptedByDataScience,
    offerState?.offerStatus?.isOfferCountered,
    offerState?.offerStatus?.offerExpirationDate,
  ]);

  // tslint:disable-next-line:no-shadowed-variable
  function isLoadOfferable(load: AvailableLoadSummary): boolean {

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const activityDateDiff = load.activityDateTime?.getTime() - today?.getTime();

    if (load.isHazMat) {
      return showMakeOfferHazmat;
    }
    if (load.isRegulatedByStf) {
      return showMakeOfferStf;
    }
    if (load.equipmentCode === 'F') {
      return showMakeOfferFlatbed;
    }
    if (load.isTankerEndorsementRequired) {
      return showMakeOfferTanker;
    }
    if (load.hasDropTrailer) {
      return showMakeOfferDroptrailer;
    }
    if (load.isTeamFlag) {
      return showMakeOfferTeamload;
    }
    // 14 to 25 days
    if (activityDateDiff >= 1209600000 && activityDateDiff <= 2160000000) {
      return showMakeOfferActivitydate;
    }

    return showMakeOffer;
  }

  return offerState;
};

export default useCarrierOffer;
