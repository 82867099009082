import './bounce-warning-modal.component.scss';
import { Modal } from 'shared/components/modal/modal.component';
import { Button } from 'shared/components/elements/elements.components';
import { Translation } from 'shared/components/translation/translation.component';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ReloadMetadataContext } from 'shared/reloads/analytics/reload-metadata.context';
import { hideModal, showModal } from 'shared/components/modal/modal.actions';
import { BIN_FORM_V2 } from 'app/globals/constants';
import { LoadSummaryCarrierOffer } from 'shared/models/loads/load-summaries/load-summary-carrier-offer';

export const BOUNCE_WARNING = 'bounce-warning';

interface Props {
  load: AvailableLoadSummary;
  bookAcceptedOffer: boolean;
  offer?: LoadSummaryCarrierOffer;
  onHide?: () => any;
  continueToAcceptedOfferBooking?: (result: boolean) => void;
  position?: number;
}

export const BounceWarningModal = ({ load, offer, onHide, bookAcceptedOffer, continueToAcceptedOfferBooking, position }: Props) => {
  const dispatch = useDispatch();
  const { reloadData } = useContext(ReloadMetadataContext);

  const hideBounceWarningModal = useCallback(() => {
    dispatch(hideModal(BOUNCE_WARNING));

    if (offer) {
      dispatch(showModal(BIN_FORM_V2, { load, offer, position: position }));
    } else if (bookAcceptedOffer) {
      continueToAcceptedOfferBooking(true);
    } else {
      dispatch(showModal(BIN_FORM_V2, { load, position: position, reloadData }));
    }

  }, [dispatch]);

  return (
    <div className="bounce-warning-modal">
      <Modal
        name={BOUNCE_WARNING}
        header={() =>
          <div className="warning">
            <Translation resource="WARNING" />
          </div>
        }
        onHide={onHide}
        children={() =>
          <div className="bounce-warning-message">
            <h1>
              Canceling this load after booking can limit your opportunities to haul future loads.
            </h1>
          </div>
        }
        footer={() =>
          <Button
            className="js-ok-button"
            id="bounce-warning-ok-button"
            btnPrimary
            mobileResponsive
            onClick={hideBounceWarningModal}
            resource="OK"
          />
        }
      />
    </div>
  );
};
