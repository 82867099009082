import { Button } from 'shared/components/elements/button.component';
import { ShipmentNotes } from './shipment-notes';
import { useTranslation } from 'react-i18next';
import './shipment-notes.component.scss';

interface ShipmentNotesDetailViewProps {
    notes: shipmentNoteJSON[];
    toggleView: () => any;
}

export const ShipmentNotesDetailViewComponent = ({ notes = [], toggleView }: ShipmentNotesDetailViewProps) => {
    const { t } = useTranslation();
    return  (
        <div className="shipment-notes-detail-content" data-testid="shipment-notes-detail-content">
            <Button
                id="shipment-notes-back-button"
                btnLink
                onClick={toggleView}
                className="shipment-notes-back-button"
            >
                {t('LOAD_NOTES')}
            </Button>
            <ShipmentNotes notes={notes} isDetail={true} />
        </div>
    );
};
