import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { IsCarrierRestrictedOnLoad } from 'app/util/loads/cap-restricted-function';
import { getScheduledReleaseDateTime } from 'app/util/loads/scheduled-release-function';
import { LoadReleaseScheduleTimer } from '../load-release-schedule/load-release-schedule-timer.component';

import './load-release-schedule.component.scss';

interface LoadStickyReleaseScheduleProps {
  loadCarrierTier: string;
  openToCoreDateTime: Date;
  openToBaseDateTime: Date;
}

export const LoadStickyReleaseSchedule = ({ loadCarrierTier, openToCoreDateTime, openToBaseDateTime }: LoadStickyReleaseScheduleProps) => {
  const { enableLoadDetailsShotClock } = useFlags();
  if (!enableLoadDetailsShotClock) {
    return;
  }

  const { t } = useTranslation();
  const upperLoadCarrierTier = (loadCarrierTier ?? 'None').toUpperCase();
  const carrierDetails = useSelector(s => s.auth.carrier);

  const isCarrierRestrictedOnLoad = IsCarrierRestrictedOnLoad(loadCarrierTier, carrierDetails.capCode);
  const [timerElapsed, setTimerElapsed] = useState(false);
  const scheduledReleaseDateTime = getScheduledReleaseDateTime(loadCarrierTier, carrierDetails.capCode, openToBaseDateTime, openToCoreDateTime);

  if (isCarrierRestrictedOnLoad || !["KEY", "CORE"].includes(upperLoadCarrierTier) || !scheduledReleaseDateTime) {
    return;
  }

  return (
    <div className={`${upperLoadCarrierTier} cap-scheduled`}>
      {timerElapsed ?
        <>
          {t("CAP_SCHEDULED_RELEASE_REFRESH")}
        </> :
        <div>
          <img className="cap-icon" src={upperLoadCarrierTier === "KEY" ? "/assets/images/key_orange.svg" : "/assets/images/stars_blue.svg"} />
          <b>{loadCarrierTier} Load </b>
          <LoadReleaseScheduleTimer
            loadReleaseSchedule={scheduledReleaseDateTime}
            loadCarrierTier={upperLoadCarrierTier}
            onTimeout={() => {
              setTimerElapsed(true);
            }}
          />
        </div>
      }
    </div>
  );
}