import { useTranslation } from 'react-i18next';
import { CarrierTier } from 'shared/enums/carrier-tier.enum';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IsCarrierRestrictedOnLoad } from 'app/util/loads/cap-restricted-function';
import { useCarrierDetails } from 'app/hooks/store/use-carrier-details.hook';

import './cap-description.component.scss';

interface Props {
  loadCarrierTier?: string;
}

export const CapDescription = ({ loadCarrierTier }: Props) => {
  const { findLoadsCapRestrictionsWeb } = useFlags();
  const { t } = useTranslation();
  const carrierCapCode = useCarrierDetails()?.capCode;
  const upperLoadTier = (loadCarrierTier ?? 'None').toUpperCase();

  const isKeyLoad: boolean = upperLoadTier == CarrierTier.KEY;

  if (!findLoadsCapRestrictionsWeb || IsCarrierRestrictedOnLoad(upperLoadTier, carrierCapCode) || ![CarrierTier.KEY, CarrierTier.CORE].includes(CarrierTier[upperLoadTier])) {
    return;
  }

  return <div className={`${loadCarrierTier} cap-labeled`} data-testid="cap-label">
    <img className="cap-label-icon" src={isKeyLoad ? "/assets/images/key_orange.svg" : "/assets/images/stars_blue.svg"} data-testid="cap-label-image"/>
    <div>
      <span><b> {isKeyLoad ? t('KEY_LOAD') : t('CORE_EXCLUSIVE')}</b> {t('CAP_RELEASES')}</span>
    </div>
  </div>;
};
