import { Translation } from 'shared/components/translation/translation.component';
import { useFlags } from 'launchdarkly-react-client-sdk';
import DevicesIcon from '@mui/icons-material/Devices';

interface Props {
  isWebExclusive: boolean;
}

export const WebExclusiveLabel = ({ isWebExclusive }: Props) => {
  const { findLoadsWebExclusiveWeb } = useFlags();

  if (!findLoadsWebExclusiveWeb || !isWebExclusive) {
    return;
  }

  return <div className="web-exclusive-label" data-testid="web-exclusive-label">
    <DevicesIcon style={{ marginRight: "2px", verticalAlign: "middle", color: "#005C05", height: "16px" }} />
    <span><Translation resource='EXCLUSIVELY_ONLINE' /></span>
  </div>;
};
