import { useLoadsSearchState } from '../hooks/use-loads-search-state.hook';
import { hideModal, showModal } from 'shared/components/modal/modal.actions';
import { VIEW_ALL_RELOADS } from 'shared/reloads/modals/reloads-view-all-modal.component';
import { useCallback, MouseEvent, useState } from 'react';
import { LoadStickyTitleBar } from './load-stick-title-bar/load-sticky-title-bar.component';
import { ButtonAreaComponent } from 'pages/find-loads-ver2/details/button-area/button-area.component';
import { StopDetails } from 'pages/find-loads-ver2/details/stop-details/stop-details.component';
import { LoadRequirements } from 'pages/find-loads-ver2/details/load-requirements/load-requirements.component';
import { Commodities } from 'pages/find-loads-ver2/details/commodities/commodities.component';
import { LoadStickyReleaseSchedule } from './load-release-schedule/load-sticky-release-schedule.component';
import { Reloads } from 'shared/reloads/reloads.compoment';
import { LoaderNames } from 'app/globals/constants';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { AvailableLoadDetail } from 'shared/models/loads/available-load-detail.model';
import { useDispatch } from 'react-redux';
import { RELOAD_DETAILS_MODAL } from '../modals/reload-details-modal.context';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LOAD_DETAILS_MODAL } from '../modals/load-details-modal.context';
import { ReloadsData } from '../hooks/use-reloads.hook';
import { ShipmentNotesDetailViewComponent } from 'pages/find-loads-ver2/details/shipment-notes/shipment-notes-detail-view.component';
import { BounceWarningModal } from 'pages/find-loads-ver2/modals/bounce-warning-modal.component';
import { CapDescription } from 'pages/find-loads-ver2/details/cap-restricted/cap-description.component';
import { StfBanner } from 'pages/find-loads-ver2/details/stf-banner.component';
import { WebExclusiveLabel } from 'shared/components/web-exclusive/web-exclusive-label.component';
import { LoadMapComponent } from 'pages/find-loads-ver2/details/load-map.component';

export interface AvailableProps {
  load: AvailableLoadSummary;
  loadDetails: AvailableLoadDetail;
  reloadsData: ReloadsData;
  handleOnClose?: () => void;
  handleFocusSelectedLoadResult?: () => void;
  sendLDEvents: boolean;
  hideLoadMap?: boolean;
  position?: number;
}

function useReloadClickCallback(primalLoadNumber: number, callVariation: ReloadsCallVariation): (
  e: MouseEvent,
  selectedReload?: AvailableLoadSummary
) => void {
  const dispatch = useDispatch();
  return useCallback((e: MouseEvent, selectedReload?: AvailableLoadSummary) => {
    dispatch(showModal(
      RELOAD_DETAILS_MODAL,
      { load: selectedReload, reloadData: { primalLoadNumber, callVariation, reloadNumber: selectedReload?.number } }
    ));
    dispatch(hideModal(LOAD_DETAILS_MODAL));
  }, [dispatch, primalLoadNumber, callVariation]);
}

export const AvailableLoadComponent = ({ load, loadDetails, handleOnClose, handleFocusSelectedLoadResult, reloadsData, sendLDEvents, hideLoadMap, position }: AvailableProps) => {
  const [isViewingShipmentNotesDetail, setIsViewingShipmentNotesDetail] = useState(false);
  const [isReloadsSectionRendered, setIsReloadsSectionRendered] = useState(false);
  const { performedSuggestedLoadSearch } = useLoadsSearchState();
  const hasCommodities =
    loadDetails.items &&
    ((loadDetails.items.length === 1 &&
      loadDetails.items[0].commodityName !== null) ||
      loadDetails.items.length > 1);

  const { preBookReloadDetails } = useFlags();
  const dispatch = useDispatch();

  const showViewAllModal = useCallback(() => {
    dispatch(showModal(VIEW_ALL_RELOADS, { load: load }));
  }, [dispatch, load]);

  const showReloadDetailsModal = useReloadClickCallback(
    load.number,
    performedSuggestedLoadSearch ? 'PreBookSplashPage' : 'PreBook'
  );

  const showMap = !hideLoadMap;

  const toggleShipmentNotesView = () => setIsViewingShipmentNotesDetail(!isViewingShipmentNotesDetail);

  return (
    <>
      <LoadStickyTitleBar
        loadNumber={load.number}
        onClose={handleOnClose}
        focusSelectedLoadResult={handleFocusSelectedLoadResult}
        reloadsCount={reloadsData?.numberOfReloads}
        isReloadsSectionRendered={isReloadsSectionRendered}
      />
      <WebExclusiveLabel isWebExclusive={load.isWebExclusive} />
      {loadDetails.autoOpenSchedules?.length > 0 && <LoadStickyReleaseSchedule
        loadCarrierTier={loadDetails.carrierTier}
        openToCoreDateTime={loadDetails.openToCoreDateTime}
        openToBaseDateTime={loadDetails.openToBaseDateTime}
      />}
      <ButtonAreaComponent load={load} sendLDEvents={sendLDEvents} position={position} openToBaseDateTime={loadDetails.openToBaseDateTime} openToCoreDateTime={loadDetails.openToCoreDateTime} />
      <BounceWarningModal load={load} bookAcceptedOffer={false} position={position} />
      <div className="content">
        {isViewingShipmentNotesDetail ?
          <ShipmentNotesDetailViewComponent
            notes={loadDetails?.shipmentNotes ?? []}
            toggleView={toggleShipmentNotesView}
          /> :
          <>
            {load?.isRegulatedByStf && <StfBanner />}
            <CapDescription loadCarrierTier={loadDetails.carrierTier} />
            <ErrorBoundary>
              {showMap &&
                <LoadMapComponent
                  loadNumber={load.number}
                  stops={load.stops} />
              }
            </ErrorBoundary>
            <StopDetails
              stopDetails={loadDetails.stopDetails}
              sourceUnitOfMeasure={loadDetails.unitOfMeasure}
              distance={loadDetails.distance}
              pickStartTime={loadDetails.pickStartTimeV2}
              pickEndTime={loadDetails.pickEndTimeV2} />
            <LoadRequirements load={loadDetails} toggleView={toggleShipmentNotesView} />
            {hasCommodities && <Commodities items={loadDetails.items} />}
            {!!reloadsData?.showReloads &&
              <Reloads
                reloadsData={reloadsData}
                onReloadClick={preBookReloadDetails ? showReloadDetailsModal : null}
                onViewAllClick={showViewAllModal}
                loaderName={LoaderNames.FindLoadsVer2LoadDetailReloads}
                primalLoadNumber={load.number}
                callVariation={performedSuggestedLoadSearch ? 'PreBookSplashPage' : 'PreBook'}
                setIsReloadsSectionRendered={setIsReloadsSectionRendered}
              />
            }
          </>}
      </div>
    </>
  );
};

export default AvailableLoadComponent;
