import React from 'react';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { LoadLocation } from 'shared/loads/load-location.component';
import { AvailableLoadLocationType } from 'shared/enums/available-loads/location-type.enum';
import { LoadRequirements } from 'shared/loads/load-requirements.component';

interface Props {
  load: AvailableLoadSummary;
  showDestinationDeadhead: boolean;
  showOriginDeadhead: boolean;
  sectionName: string;
}

export const LoadCard: React.FC<Props> = ({load, showDestinationDeadhead, showOriginDeadhead, sectionName}) => {
  return (
    <>
      <div className={`${sectionName}-section ${sectionName}-locations`}>
        <LoadLocation
          load={load}
          showDeadhead={showOriginDeadhead}
          locationType={AvailableLoadLocationType.ORIGIN}
          sectionName={sectionName}
        />
        <div className="arrow">
          <img src="/assets/images/Arrow_24dp.svg" aria-hidden="true"/>
        </div>
        <LoadLocation
          load={load}
          showDeadhead={showDestinationDeadhead}
          locationType={AvailableLoadLocationType.DESTINATION}
          sectionName={sectionName}
        />
      </div>
      <div className={`${sectionName}-section ${sectionName}-requirements`}>
        <LoadRequirements load={load}/>
      </div>
    </>
  );
};
