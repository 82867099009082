import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/elements/elements.components';
import { ShipmentNotes } from './shipment-notes';

import './shipment-notes.component.scss';

interface ShipmentNotesComponentProps {
  notes: shipmentNoteJSON[];
  toggleView: () => any;
}

export function ShipmentNotesComponent({ notes, toggleView }: ShipmentNotesComponentProps) {
  const { t } = useTranslation();
  const hasMoreNotes = notes.length > 2;
  const hasNotes = notes.length > 0;

  const renderViewAllButton = () => {
    return (
      <Button
        data-testid="shipment-notes-view-all-button"
        id="shipment-notes-view-all-button"
        btnLink
        onClick={toggleView}
        className="shipment-notes-view-all-button"
      >
        {t('VIEW_ALL')}
      </Button>
    );
  }

  return (
    <div className="notes-section-preview">
      <div className="label">{t('NOTES')}</div>
      {hasNotes ?
        (<>
          <ShipmentNotes notes={notes} />
          {hasMoreNotes && renderViewAllButton()}
        </>)
        :
        <p className="shipment-note" data-test="no-notes">{t('THERE_ARE_NO_NOTES_TO_DISPLAY')}</p>
      }
    </div>
  );
}
