import { useFlags } from 'launchdarkly-react-client-sdk';
import { useResources } from 'app/hooks/store/use-resources.hook';
import { DetailsEquipmentMeasurements } from 'pages/find-loads/details-equipment-measurements.formatter';
import { EndorsementList } from 'shared/find-loads/components/endorsement-list.component';
import { EquipmentTypeList } from 'shared/components/loads/equipment-types-list.component';
import { SpecializedEquipmentTypeList } from 'shared/components/loads/specialized-equipment-type-list.component';
import { Distance, Length, Temperature, Weight } from 'shared/components/formatters/conversion/conversion.components';
import { YesNo } from 'shared/components/formatters/yes-no.formatter';
import { useTranslation } from 'react-i18next';
import { AvailableLoadDetail } from 'shared/models/loads/available-load-detail.model';
import { LoadRequirement } from 'pages/find-loads-ver2/details/load-requirements/load-requirement.component';
import { ShipmentNotesComponent } from 'pages/find-loads-ver2/details/shipment-notes/shipment-notes.component';

import './load-requirements.component.scss';

interface OwnProps {
  load: AvailableLoadDetail;
  toggleView?: () => any;
}

export const LoadRequirements = ({ load, toggleView }: OwnProps) => {
  const resources = useResources();
  const { loadNotes } = useFlags();
  const { t } = useTranslation();

  return (
    <div className="requirements-section">
      <div className="title" role="heading" aria-level={3}>
        {t('REQUIREMENTS')}
      </div>
      <div className="section-content">
        <LoadRequirement name={resources.EQUIPMENT}>
          {load.specializedEquipmentCode 
            ? <SpecializedEquipmentTypeList
              specializedCode={load.specializedEquipmentCode}
              equipmentCode={load.mode} />
            : <EquipmentTypeList equipmentCode={load.mode} />
          }
        </LoadRequirement>

        <LoadRequirement name={resources.MEASUREMENTS}>
          <DetailsEquipmentMeasurements item={load.equipment.dimension} />
        </LoadRequirement>

        <LoadRequirement
          name={resources.TEMPERATURE}
          hide={!(load.maxTemp || load.minTemp)}
        >
          <Temperature maxTemp={load.maxTemp} minTemp={load.minTemp} />
        </LoadRequirement>

        <LoadRequirement name={resources.TOTAL_WEIGHT}>
          <Weight value={load.weight} from={load.unitOfMeasure} />
        </LoadRequirement>

        <LoadRequirement name={resources.DISTANCE}>
          <Distance value={load.distance} from={load.unitOfMeasure} />
        </LoadRequirement>

        <LoadRequirement name={resources.TEAM_ONLY}>
          <YesNo value={load.isTeamLoad} />
        </LoadRequirement>

        <LoadRequirement name={resources.WEIGHT_TICKET}>
          <YesNo value={load.isWeightTickets} />
        </LoadRequirement>

        <LoadRequirement name={resources.DROP_TRAILER}>
          <YesNo value={load.isDropTrailer} />
        </LoadRequirement>

        <LoadRequirement
          hide={!load.hasEndorsement()}
          name={resources.ENDORSEMENT}
        >
          <EndorsementList load={load} separator="/" />
        </LoadRequirement>

        <LoadRequirement
          hide={!load.strapsCount}
          name={resources.REQUIRED_STRAPS}
        >
          <span data-testid='strap-count'>{load.strapsCount}</span>
        </LoadRequirement>

        <LoadRequirement
          hide={!load.chainsCount}
          name={resources.REQUIRED_CHAINS}
        >
          <span data-testid='chain-count'>{load.chainsCount}</span>
        </LoadRequirement>

        <LoadRequirement
          hide={load.tarpType === null || load.tarpType === undefined}
          name={resources.TARP_LENGTH}
        >
          <span>
            { load.tarpType > 0 
              ? <Length value={load.tarpType} from={load.unitOfMeasure} />
              : t('TARP_NOT_REQUIRED')
            }
          </span>
        </LoadRequirement>

        <LoadRequirement hide={!load.coilRacks} name={resources.COIL_RACKS}>
          <span data-testid='coil-racks'>{load.coilRacks}</span>
        </LoadRequirement>
      </div>
      {loadNotes && <ShipmentNotesComponent notes={load.shipmentNotes ?? []} toggleView={toggleView} />}
    </div>
  )
};
