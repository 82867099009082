import { useButtonClickStream } from 'shared/components/click-stream/use-button-clickstream.hook';
import LoadActions from 'shared/loads/load-actions.component';
import { LoadCard } from 'shared/loads/load-card.component';
import { ButtonType } from 'shared/models/click-stream/button-type.model';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { CapLabel } from 'shared/components/cap-restricted/cap-label.component';
import React, { useCallback } from 'react';

import './reload-item.component.scss';

interface Props {
  reload: AvailableLoadSummary;
  onReloadClick?: (e: React.MouseEvent, selectedReload?: AvailableLoadSummary) => void;
  callVariation: ReloadsCallVariation;
  primalLoadNumber: number;
}

function useTrackReloadItemClick(primalLoadNumber: number, callVariation: ReloadsCallVariation, reloadNumber: number) {
  return useButtonClickStream(`${primalLoadNumber}-${callVariation}-reload-${reloadNumber}`, ButtonType.Custom, true);
}

function useOnReloadClick({ reload, onReloadClick, callVariation, primalLoadNumber }: Props) {
  const trackClick = useTrackReloadItemClick(primalLoadNumber, callVariation, reload.number);
  const onReloadClickCallback = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if (onReloadClick) {
      onReloadClick(e, reload);
      trackClick(e);
    }
  }, [onReloadClick, reload]);
  return {
    onReloadClick: onReloadClickCallback,
    isClickable: !!onReloadClick,
  };
}

export const ReloadItem = (props: Props) => {
  const { reload } = props;
  const { onReloadClick, isClickable } = useOnReloadClick(props);

  return (
    <div className={`reload-item ${isClickable ? 'clickable' : ''}`} onClick={onReloadClick}>
      <div className="reload-pill-area">
        <CapLabel loadTier={reload.carrierTier} />
      </div>
      <div className="reload-item-section reload-item-actions">
        <LoadActions sectionName="reload-item" load={reload} />
      </div>
      <LoadCard
        load={reload}
        showDestinationDeadhead={false}
        showOriginDeadhead={true}
        sectionName="reload-item"/>
    </div>
  );
};
