import React from 'react';
import { useMemo } from 'react';

import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { Distance, Weight } from 'shared/components/formatters/conversion/conversion.components';
import { EquipmentMeasurements } from 'shared/components/formatters/equipment-measurements.formatter';
import { EquipmentTypeList } from 'shared/components/loads/equipment-types-list.component';
import { SpecializedEquipmentTypeList } from 'shared/components/loads/specialized-equipment-type-list.component';
import { EndorsementList } from 'shared/find-loads/components/endorsement-list.component';
import { TruncatedElement } from 'shared/components/elements/truncated-element.component';
import { useResources } from 'app/hooks/store/use-resources.hook';

import './load-location.component.scss';

interface Props {
  load: AvailableLoadSummary;
}

export const LoadRequirements: React.FC<Props> = ({ load }) => {
  const resources = useResources();
  const hasSpecializedEquipment = useMemo(() => {
    return load.specializedEquipmentCode != null &&
      (load.specializedEquipmentCode.includes('F') || load.specializedEquipmentCode.includes('B'));
  }, [load.specializedEquipmentCode]);

  return (
    <>
      <div>
        <div className="js-load-distance no-wrap">
        <div className="sr-only">{resources.DISTANCE}</div>
          <Distance
            value={Math.ceil(load.distance)}
            from={load.measurementType}
          />
        </div>
        <div className="js-load-stop-count help-text no-wrap">
          <span>
            {load.pickCount} {load.pickCount > 1 ? resources.PICKS : resources.PICK_LOWER}, {load.dropCount} {load.dropCount > 1 ? resources.DROPS : resources.DROP_LOWER}
          </span>
        </div>
      </div>
      <div>
        <div className="js-load-weight no-wrap">
        <div className="sr-only">{resources.WEIGHT}</div>
          <Weight value={load.weight} from={load.measurementType} />
        </div>
        {load.hasEndorsements()
          ? (
            <div className="js-load-endorsements">
              <div className="sr-only">{resources.ENDORSEMENT}</div>
              <EndorsementList load={load} />
            </div>
          ) : null
        }
      </div>
      <div className="load-equipment-type js-load-equipment-type">
        <div className="sr-only">{resources.EQUIPMENT_TYPE}</div>
        <TruncatedElement maxLines={2}>
          {hasSpecializedEquipment
            ? (
              <SpecializedEquipmentTypeList
                specializedCode={load.specializedEquipmentCode}
                equipmentCode={load.equipmentCode}
              />
            ) : (
              <EquipmentTypeList
                equipmentCode={load.equipmentCode} />
            )
          }
        </TruncatedElement >
        <div className="help-text">
          <div className="sr-only">{resources.EQUIPMENT_SIZE}</div>
          <EquipmentMeasurements item={load} />
        </div>
      </div>
    </>
  );
};
