import { ReloadEventData } from 'features/analytics/redux/analytics-v2.actions';
import { ReloadMetadataContext } from 'shared/reloads/analytics/reload-metadata.context';
import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { OFFER_MODAL } from 'pages/offers/modals/offer-modal.context';
import { hideModal, showModal } from 'shared/components/modal/modal.actions';
import { useModal } from 'app/hooks/use-modal.hook';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import useCarrierOffer, { OfferState, OfferStatus } from 'pages/find-loads-ver2/hooks/use-carrier-offer.hook';
import { LOAD_DETAILS_MODAL } from '../../modals/load-details-modal.context';
import { useOfferAnalytics } from 'features/analytics/hooks/use-offer-analytics.hook';
import { BOUNCE_WARNING } from '../../modals/bounce-warning-modal.component';
import { CarrierQualificationStatus } from 'shared/enums/carrier-qualification-status.enum';
import { useToastManager } from 'shared/components/toast/use-toasts.hook';
import { useResources } from 'app/hooks/store/use-resources.hook';
import AvailableLoadOfferStatus from 'shared/enums/available-load-offer-status';
import { LoaderNames, BIN_FORM_V2 } from 'app/globals/constants';
import { useAcceptCounteredOffer } from '../../hooks/use-accept-countered-offer.hook';
import { RELOAD_DETAILS_MODAL } from '../../modals/reload-details-modal.context';
import { LDClient } from 'launchdarkly-js-client-sdk';
import { Container } from 'typedi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BounceWarningExperiment } from 'shared/enums/launch-darkly-flags/bounce-warning-experiment.enum';
import { EVENT_TYPES } from '@features/analytics/constants';
import { useBookAnalytics } from 'features/analytics/hooks/use-book-analytics.hook';
import { ButtonArea } from 'pages/find-loads-ver2/details/button-area/button-area';
import { usePublishBookAction, usePublishOfferAction } from '@app/hooks/use-publish-analytics-action.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';

import './button-area.component.scss';

interface OwnProps {
  load: AvailableLoadSummary;
  offerState?: OfferState;
  sendLDEvents: boolean;
  position?: number;
  openToCoreDateTime: Date;
  openToBaseDateTime: Date;
}

const shouldOfferBeAccepted = (offerStatus: OfferStatus) => {
  return offerStatus?.isOfferCountered && !offerStatus?.isOfferExpired && (offerStatus?.availableLoadOfferStatus !== AvailableLoadOfferStatus.ACCEPTED);
};

export const ButtonAreaComponent = ({ load, position, sendLDEvents, openToCoreDateTime, openToBaseDateTime }: OwnProps) => {
  const dispatch = useDispatch();
  const trackOfferEvent = useOfferAnalytics();
  const trackBookEvent = useBookAnalytics();
  const offerState = useCarrierOffer(load);
  const { reloadData } = useContext(ReloadMetadataContext);
  const toasts = useToastManager();
  const resources = useResources();
  const carrierDetails = useSelector(s => s.auth.carrier);
  const [acceptOffer] = useAcceptCounteredOffer(offerState?.carrierOffer?.latestOffer?.offerId, load?.number, LoaderNames.BinFormModalV2);
  const [showBounceWarningModal] = useModal(BOUNCE_WARNING);
  const showBookItNowModal = (load: AvailableLoadSummary, position?: number, reloadData?: ReloadEventData) => showModal(
    BIN_FORM_V2,
    { load, position, reloadData }
  );
  const { bounceWarningExperiment } = useFlags();
  const publishBookAction = usePublishBookAction();
  const publishOfferAction = usePublishOfferAction();

  const { canMakeNewOffer, canMakeCounterOffer } = offerState?.availableLoadOfferAction || {};

  const showOfferModal = useCallback(() => {
    if (carrierDetails?.carrierQualificationStatus === CarrierQualificationStatus.NEW || carrierDetails?.carrierQualificationStatus === CarrierQualificationStatus.PENDING) {
      toasts.error([resources.YOUR_ACCOUNT_IS_NOT_CURRENTLY_ACTIVE]);
    } else {
      dispatch(hideModal(LOAD_DETAILS_MODAL));
      dispatch(hideModal(RELOAD_DETAILS_MODAL));
      dispatch(showModal(OFFER_MODAL, { load, reloadData, makeNewOffer: canMakeNewOffer, isCounterOffer: canMakeCounterOffer, position: position }));
      trackOfferEvent(EVENT_TYPES.OFFER_SCREEN_VIEWED, load, position, { isCounterOffer: canMakeCounterOffer }, reloadData);
      publishOfferAction('OfferClicked', load.number);
    }
  }, [load, reloadData, canMakeNewOffer, canMakeCounterOffer, position, carrierDetails?.carrierQualificationStatus]);

  const handleBookClick = useCallback(() => {
    if (sendLDEvents) {
      const ldClient = Container.get<LDClient>('LD_CLIENT');
      ldClient.track('load-detail-pane-book-button-click');
    }
    trackBookEvent(EVENT_TYPES.BIN_CLICKED, load, position, reloadData);
    publishBookAction('BookClicked', load.number, offerState?.bookPrice?.value, offerState?.bookPrice?.currencyCode);

    if (bounceWarningExperiment === BounceWarningExperiment.ShowWarningTestGroup) {
      showBounceWarningModal();
    } else {
      dispatch(showBookItNowModal(load, position, reloadData));
    }

    if (shouldOfferBeAccepted(offerState?.offerStatus)) {
      acceptOffer();
    }
  }, [sendLDEvents, load, position, reloadData, offerState]);

  return (
    <ButtonArea
      load={load}
      offerState={offerState}
      offerButtonClick={showOfferModal}
      bookButtonClick={handleBookClick}
      capCode={carrierDetails?.capCode}
      openToCoreDateTime={openToCoreDateTime}
      openToBaseDateTime={openToBaseDateTime}
    />
  );
};