import { FC, useMemo } from 'react';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { Currency } from 'shared/components/formatters/currency.formatter';
import { OfferStatus } from 'pages/find-loads-ver2/offers/offer-status.component';
import { Translation } from 'shared/components/translation/translation.component';
import { useResources } from 'app/hooks/store/use-resources.hook';
import { useTimer } from 'app/hooks/use-timer.hook';
import { useCarrierOffer } from 'pages/find-loads-ver2/hooks/use-carrier-offer.hook';
import { useIsBookUnavailable } from 'pages/find-loads-ver2/hooks/use-is-book-unavailable.hook';
import { CapLocked } from 'shared/components/cap-restricted/cap-locked.component';

export interface FindLoadResultActionsProps {
  load: AvailableLoadSummary;
  sectionName: string;
}

export const LoadActions: FC<FindLoadResultActionsProps> = ({load, sectionName}) => {
  const resources = useResources();
  const isLoadBookUnavailable = useIsBookUnavailable(load);
  const offerState = useCarrierOffer(load);
  const { carrierOffer, offerStatus, availableLoadOfferAction, bookPrice } = offerState;

  const timeLeft: string = useTimer({ timerExpirationDate: offerStatus?.offerExpirationDate });

  const rate = bookPrice?.value;
  const currencyCode = bookPrice?.currencyCode;
  const hasValidRate: boolean = rate > 0 && !!currencyCode;

  const OfferElement = useMemo(() => {
    const offer = isLoadBookUnavailable ? null : carrierOffer?.latestOffer;
    if (!offer) {
      return <div></div>;
    }
    return (
      <div id={`${sectionName}-action_offer-status-${load.number}`} className="js-offer-status" >
        <div className="sr-only">{resources.STATUS}</div>
          <OfferStatus offerState={offerState} />
      </div>
    );
  }, [offerState, load?.number]);

  const RateElement = useMemo(() => {
    return hasValidRate && (
      <div
        id={`${sectionName}-action_load-rate-${load.number}`}
        className="js-load-rate" >
        <div className="sr-only">{resources.RATE}</div>
        <h4>
          <Currency value={rate} currency={currencyCode} />
        </h4>
      </div>
    );
  }, [load.number, hasValidRate, rate, currencyCode]);

  const PrimaryActionElement = useMemo(() => {
    const labelResource: ResourceKey = availableLoadOfferAction?.resourceKey;
    const showTimer = !offerStatus?.isOfferExpired && timeLeft && (offerStatus?.isOfferAcceptedByDataScience || offerStatus?.isOfferCountered);

    return (
      <label
        className="js-primary-action"
        aria-label={resources.ACTION}
        id={`${sectionName}-action_button-${load.number}`}>
        <span>
          {showTimer && <span className="time-left">{timeLeft} min </span>}
          {availableLoadOfferAction?.isCapLocked
            ? <CapLocked carrierTier={load.carrierTier} variant={availableLoadOfferAction?.isCapRestricted ? 'restricted' : 'locked'} />
            : <Translation resource={labelResource} /> }
        </span>
      </label>
    );
  }, [load.number, availableLoadOfferAction, timeLeft, offerStatus?.isOfferExpired, offerStatus?.isOfferAcceptedByDataScience , offerStatus?.isOfferCountered]);

  return (
    <div
      id={`${sectionName}-action_load-${load.number}`}
      className="action-area">
      {OfferElement}
      {RateElement}
      <div>
        {PrimaryActionElement}
      </div>
    </div>
  );
};

export default LoadActions;
