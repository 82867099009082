import React from 'react';

import { CityStateCountryFormatter } from 'shared/components/formatters/city-state-country.formatter';
import { Timing } from 'shared/components/loads/stops/timing.component';
import { Distance } from 'shared/components/formatters/conversion/conversion.components';
import './load-location.component.scss';
import { AvailableLoadLocationType } from 'shared/enums/available-loads/location-type.enum';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { useResources } from 'app/hooks/store/use-resources.hook';
import TruncatedElement from 'shared/components/elements/truncated-element.component';

interface Props {
  load: AvailableLoadSummary;
  showDeadhead: boolean;
  locationType: AvailableLoadLocationType;
  sectionName: string;
}

export const LoadLocation: React.FC<Props> = ({
    load,
    showDeadhead,
    locationType,
    sectionName
  }) => {
    const isOrigin = locationType === AvailableLoadLocationType.ORIGIN;
    const address = isOrigin ? load.getOriginAddress() : load.getDestinationAddress();
    const deadhead = isOrigin ? load.originDeadhead : load.destinationDeadhead;
    const startTime = isOrigin ? load.pickStartTimeV2 : load.dropStartTimeV2;
    const endTime = isOrigin ? load.pickEndTimeV2 : load.dropEndTimeV2;
    const resources = useResources();

  return (
    <div className={`${sectionName}-location js-load-${isOrigin ? 'origin' : 'destination'}`}>
      <div className="location-deadhead-group">
        <div className="sr-only">{isOrigin ? resources.ORIGIN_INFORMATION : resources.DESTINATION_INFORMATATION}</div>
        <TruncatedElement maxLines={1} className="location-deadhead-address">
          <CityStateCountryFormatter address={address} forceTitleCasing={true} />
        </TruncatedElement>
        {(showDeadhead) && (
          <span className="help-text location-deadhead-distance">
            <div className="sr-only">{isOrigin ? resources.DEADHEAD_DISTANCE_FROM_PICKUP : resources.DEADHEAD_DISTANCE_TO_DROP_OFF}</div>
            (<Distance value={deadhead} float={1} />)
          </span>
        )}
      </div>
      <div className="time help-text">
        <div className="sr-only">{isOrigin ? resources.PICKUP_DATE : resources.DROP_OFF_DATE}</div>
        <Timing
          withFlex={true}
          start={startTime}
          end={endTime}
        />

      </div>
    </div>
  );
};
