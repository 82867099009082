import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/empty';

import { useAjaxResponse } from 'app/hooks/ajax/use-ajax-response.hook';
import { AvailableLoadsRepository } from 'app/repositories/available-loads.repository';
import { useRepository } from 'app/hooks/ajax/use-repository.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UUID } from 'angular2-uuid';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { LoadsSearchState } from './use-loads-search-state.hook';
import { ReloadsType } from 'shared/enums/reloads-type.enum';
import { useEffect, useMemo, useState } from 'react';
import { storeReloadOffers } from 'shared/find-loads/redux/find-loads.actions';
import { useDispatch } from 'react-redux';
import { LoadSummaryCarrierOffer } from 'shared/models/loads/load-summaries/load-summary-carrier-offer';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { CarrierBook } from 'shared/models/loads/load-books/carrier-book.model';
import { useIsTimeFormat } from 'app/hooks/store/use-time-format.hook';
import { TimeFormat } from 'shared/enums/time-format.enum';
import { formatTime } from 'shared/components/formatters/date-time.formatter';
import { convertUnits } from 'shared/components/formatters/unit-conversion.formatter';
import { MeasurementType } from 'shared/enums/measurement-type.enum';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { getUnit } from 'shared/components/formatters/unit-label.formatter';
import { useReloadsAnalytics } from 'features/analytics/hooks/use-reloads-analytics.hook';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

export interface ReloadsData {
  reloads: AvailableLoadSummary[];
  showReloads: boolean;
  origin: string;
  destination: string;
  searchRadius: string;
  requestTime: string;
  numberOfReloads: number;
}

type UseReloadsHook = (
  load: AvailableLoadSummary,
  loadsSearchState: LoadsSearchState,
  reloadsType: ReloadsType,
  loaderName: string,
  numberOfRecords?: number) => ReloadsData;

/**
 * Returns null if criteria contains reloadsCriteria
 * because in this case view all reloads page is open and there is no original search criteria.
 */
function getOriginalSearchCriteria(searchState: LoadsSearchState) {
  if (!searchState.searchCriteria || !!searchState.searchCriteria?.reloadsCriteria) {
    return null;
  }

  const { reloadsCriteria, ...searchCriteria } = searchState.searchCriteria;
  return searchCriteria;
}

export const createReloadSearchRequest = (
  searchState: LoadsSearchState,
  load: AvailableLoadSummary,
  reloadsType: ReloadsType,
  selectedReload: number = null
): ReloadsSearchCriteriaJSON => {

  if (!load) {
    return null;
  }
  const callVariation = getCallVariation(searchState.performedSuggestedLoadSearch, reloadsType);
  return {
    selectedReloadLoadNumber: selectedReload,
    callVariation: callVariation,
    findLoadsSearchUuid: searchState.searchCorrelationId,
    selectedLoadNumber: load.number,
    criteria: getOriginalSearchCriteria(searchState),
    searchedLoadNumber: searchState.searchCriteria?.loadNumber,
    primalLoadDetails: {
      primalLoadNumber: load.number,
      activityDate: load.activityDateTime,
      distance: load.distance,
      origin: {
        state: load.originStateProvinceCode,
        latitude: load.originLatitude,
        longitude: load.originLongitude,
        city: load.originCity,
      },
      destination: {
        state: load.destinationStateProvinceCode,
        latitude: load.destinationLatitude,
        longitude: load.destinationLongitude,
        city: load.destinationCity,
      },
      pickupDateRangeStart: load.pickStartTimeV2,
      pickupDateRangeEnd: load.pickEndTimeV2,
      dropoffDateRangeStart: load.dropStartTimeV2,
      dropoffDateRangeEnd: load.dropEndTimeV2,
      equipmentType: load.equipmentCode,
      deadheadMilesToOrigin: load.originDeadhead,
      stops: load.stops?.map(d => ({
        appointmentType: d.appointmentType,
        stopType: d.stopType,
        arriveByStartDateTime: d.arriveByStartDateTime,
        arriveByEndDateTime: d.arriveByEndDateTime,
        sequenceNumber: d.sequenceNumber,
        stopNumber: d.stopNumber,
        warehouseOpeningDateTime: d.warehouse.toJson().warehouseHoursStart,
        warehouseClosingDateTime: d.warehouse.toJson().warehouseHoursEnd,
      }))
    }
  };
};

const getShowReloads = (reloadsType: ReloadsType, flags: LDFlagSet): boolean => {
  if (reloadsType === ReloadsType.PostBookReloads) {
    return flags['postBookReloads'];
  }
  if (reloadsType === ReloadsType.PreBookReloads) {
    return flags['preBookReloads'];
  }
  return false;
};

const getCallVariation = (performedSuggestedLoadSearch: boolean, reloadsType: ReloadsType): ReloadsCallVariation => {
  if (reloadsType === ReloadsType.PostBookReloads) {
    if (performedSuggestedLoadSearch) {
      return 'PostBookSplashPage';
    }
    return 'PostBook';
  }

  if (reloadsType === ReloadsType.PreBookReloads) {
    if (performedSuggestedLoadSearch) {
      return 'PreBookSplashPage';
    }
    return 'PreBook';
  }
  return 'Unknown';
};

const carrierOffersReducer = (accumulator: LoadSummaryCarrierOffer[], reload: AvailableLoadSummary) => {
  const offers = reload.carrierOffers?.map(carrierOffer => {
    const offer = carrierOffer;
    offer.loadNumber = reload.number;
    return offer;
  });
  return [...accumulator, ...offers];
};

const reloadsReducer = (bookings: CarrierBook[]) => (accumulator: AvailableLoadSummary[], reload: AvailableLoadSummary) => {
  if (bookings?.findIndex(c => c.loadNumber === reload.number) < 0) {
    accumulator.push(reload);
  }
  return accumulator;
};

function calculateRadius(targetUnit, reloadSearchRadius?: number) {
  const radius = Math.ceil(reloadSearchRadius);
  const convertedValue = convertUnits(radius, MeasurementType.Distance, targetUnit, UnitOfMeasure.Standard) || ((radius == null || radius as any === '') ? '' : 0);

  return `${Number(convertedValue).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })} ${getUnit(targetUnit, MeasurementType.Distance)}`;
}

export const useReloads: UseReloadsHook = (load, loadsSearchState, reloadsType, loaderName, numberOfRecords) => {
  const dispatch = useDispatch();
  const is12Hour = useIsTimeFormat(TimeFormat.TwelveHour);
  const repo = useRepository(AvailableLoadsRepository);
  const targetUnit = useSelector((state: NavCarrierState) => state.auth.user.getUnitOfMeasure());
  const bookings = useSelector(state => state.findLoads.results.carrierBooks);
  const flags = useFlags();
  const [requestTime, setRequestTime] = useState<string>();

  const showReloads = useMemo(() => {
    return getShowReloads(reloadsType, flags);
  }, [reloadsType]);

  const callVariation = useMemo(() => {
    return getCallVariation(loadsSearchState.performedSuggestedLoadSearch, reloadsType);
  }, [loadsSearchState.performedSuggestedLoadSearch, reloadsType]);

  const request = useMemo(() => {
    if (!showReloads) {
      return null;
    }
    return createReloadSearchRequest(loadsSearchState, load, reloadsType);
  }, [loadsSearchState, load, reloadsType, showReloads]);

  const [reloadsState, setReloadsState] = useState<ReloadsData>({
    numberOfReloads: null,
    searchRadius: null,
    reloads: null,
    showReloads: null,
    requestTime: null,
    origin: null,
    destination: null
  });
  const { dispatchSetReloadsSearchEvent } = useReloadsAnalytics();

  const [reloadsResponse] = useAjaxResponse(() => {
    if (!showReloads || !load || !request) {
      return Observable.empty();
    }
    setRequestTime(formatTime(is12Hour, new Date(), false));
    return repo.getReloads(request, UUID.UUID())
      .catch(() => {
        return Observable.empty();
      });
  }, [load], { loaderName });

  useEffect(() => {
    const reloadOffers = reloadsState?.reloads?.reduce(carrierOffersReducer, []);
    if (reloadOffers?.length > 0) {
      dispatch(storeReloadOffers(reloadOffers));
    }
  }, [reloadsState?.reloads, dispatch]);

  useEffect(() => {
    let calculatedReloads = reloadsResponse?.loads?.reduce(reloadsReducer(bookings), []);
    const totalReloadsCounter = calculatedReloads?.length;
    if (!!numberOfRecords && numberOfRecords > 0) {
      calculatedReloads = calculatedReloads?.slice(0, numberOfRecords);
    }
    setReloadsState(
      {
        requestTime: requestTime,
        showReloads: showReloads,
        reloads: calculatedReloads,
        searchRadius: calculateRadius(targetUnit, reloadsResponse?.reloadSearchRadius),
        numberOfReloads: totalReloadsCounter,
        destination: (request?.criteria?.originCity || request?.primalLoadDetails?.origin?.city),
        origin: (request?.criteria?.destinationCity || request?.primalLoadDetails?.destination?.city),
      });

    // don't dispatch same event after booking load
    if (!!bookings?.find(c => c.loadNumber === load?.number) && reloadsType === ReloadsType.PreBookReloads) {
      return;
    }
    dispatchSetReloadsSearchEvent({
      reloads: calculatedReloads,
      searchCorrelationId: loadsSearchState.searchCorrelationId,
      primalLoadId: load?.number,
      callVariation: callVariation
    });

  }, [reloadsResponse, bookings]);

  return reloadsState;
};
